import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { I18nextProvider } from "react-i18next";
import i18n from "i18n/i18n";
import "assets/css/elements.css";
import "assets/css/modals.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { MaterialUIControllerProvider } from "context";
import { AuthProvider } from "helper";
import { LicenseInfo } from "@mui/x-license";
import { muixLicence } from "config";

LicenseInfo.setLicenseKey(muixLicence);

// main file for rendering the application
ReactDOM.render(
  <AuthProvider>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <MaterialUIControllerProvider>
          <Suspense fallback={<div className="spinner" />}>
            <App />
          </Suspense>
        </MaterialUIControllerProvider>
      </I18nextProvider>
    </BrowserRouter>
  </AuthProvider>,
  document.getElementById("root")
);
