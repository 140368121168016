import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MDLoadingButton } from "components";
import { Logout } from "services/Login";
import { useAuth, useSnackbar } from "helper";
import { logoutIamRedirectUrl } from "config";

function AuthorizationChangedDialog({ open, close }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { removeUserData } = useAuth();
  const navigate = useNavigate();
  const { showErrorSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      const { status } = await Logout();
      if (status === 200) {
        removeUserData();
        close();
        if (process.env.NODE_ENV === "development") navigate("/");
        else window.location.href = logoutIamRedirectUrl;
      }
    } catch (e) {
      showErrorSnackbar({ content: e?.response?.data?.errorTranslation || "unhandlederror" });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Dialog open={open}>
      <DialogTitle>{t("authorizationchangedtitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("authorizationchangedtext")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDLoadingButton
          color="dark"
          variant="gradient"
          onClick={handleLogout}
          isLoading={isLoading}
        >
          OK
        </MDLoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AuthorizationChangedDialog;
