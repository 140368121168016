import { useHTTPClient } from "helper";

const ProjectsService = () => {
  const { get, post, del, getDoc, put } = useHTTPClient();

  const getAllProjects = ({ pageIndex, pageSize }) =>
    get(`Projects?PageParams.PageIndex=${pageIndex}&PageParams.PageSize=${pageSize}`);

  const createProject = (data) => post("Projects", data);

  const getProject = (id) => get(`Projects/${id}`);

  const getReports = () => get("ProjectReports");

  const validateProject = (data) => post(`Validations`, data);

  const deleteProjects = (body) => del("Projects", body);

  const exportMapping = (id) => getDoc(`ExportMapping/Download?projectShortGuid=${id}`);

  const updateProject = (body) => put("Projects", body);

  const previewInline = (data, config) => post("Preview", data, config);

  const noteTags = (data) => post("Projects/NoteTags", data);

  return {
    getAllProjects,
    createProject,
    getProject,
    getReports,
    validateProject,
    deleteProjects,
    exportMapping,
    updateProject,
    previewInline,
    noteTags,
  };
};

export default ProjectsService;
