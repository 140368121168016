import { useState, useEffect, useCallback } from "react";
import { Modal, Fade, Grid, Card } from "@mui/material";
import { keyframes, styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { MDTypography, MDButton } from "components";
import { EditorService, AdditionalDocumentsService, DocumentsService } from "services";
import { InitializeEditor, PassDataToEditor } from "@inteligencijadev/html-editor/dist/bundle";
import { useSnackbar } from "helper";
import signalRHubConnectionUtility from "helper/signalRHubConnectionUtility";
import axios from "axios";

const CANCEL_TOKEN = axios.CancelToken;
let SOURCE = CANCEL_TOKEN.source();

const TYPE_CONVERTED_HTML = "convertedHtml";
const STATUS_SUCCESS = "success";
const STATUS_FAIL = "fail";
const PROJECTS_HUB_REGISTER_HANDLER = "AdditionalDocumentReady";

function EditDocumentModal({ isOpen, projectShortGuid, actionAfterSave = async () => {}, close }) {
  const [loader, setLoader] = useState({ isLoading: false, label: "" });
  const { startEditor: startEditorApiCall } = EditorService();
  const { getAdditionalDocument, additionalDocuments } = AdditionalDocumentsService();
  const { uploadHtml } = DocumentsService();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const save = async ({ data: { html: htmlContent } }) => {
    try {
      setLoader(() => ({ isLoading: true, label: "savingdocument" }));
      const { status } = await uploadHtml(
        projectShortGuid,
        { htmlContent },
        { cancelToken: SOURCE.token }
      );
      if (status === 200) {
        await actionAfterSave();
        close();
        showSuccessSnackbar({ content: "projects.details.reports.documentsaved" });
      }
    } catch (e) {
      if (!axios.isCancel(e))
        showErrorSnackbar({ content: e?.response?.data?.errorTranslation || "unhandlederror" });
    } finally {
      setLoader(() => ({ isLoading: false, label: "" }));
    }
  };

  const handleAdditionalDocumentReady = async (hubInstance, message) => {
    try {
      // message example: ADDITIONAL_DOC_READY {projectShortGuid} {documentId} {nonSpaceDelimitedDateTime}
      const documentId = message.split(" ")[2];
      const {
        status,
        data: { htmlFile: html },
      } = await getAdditionalDocument(documentId);
      if (status === 200)
        PassDataToEditor({ type: TYPE_CONVERTED_HTML, html, status: STATUS_SUCCESS });
    } catch (e) {
      PassDataToEditor({
        type: TYPE_CONVERTED_HTML,
        status: STATUS_FAIL,
        message: e?.response?.data?.errorTranslation,
      });
    } finally {
      hubInstance.dispose();
    }
  };

  const convertToHtml = async ({ data: { file } }) => {
    try {
      const { status } = await additionalDocuments(projectShortGuid, file);
      if (status === 200) {
        const projectsHub = signalRHubConnectionUtility("projectsHub");
        projectsHub.registerHandler(PROJECTS_HUB_REGISTER_HANDLER, (message) =>
          handleAdditionalDocumentReady(projectsHub, message)
        );
        projectsHub.start();
      }
    } catch (e) {
      PassDataToEditor({
        type: TYPE_CONVERTED_HTML,
        status: STATUS_FAIL,
        message: e?.response?.data?.errorTranslation,
      });
    }
  };

  const getConvertedHtml = async ({ data: { id } }) => {
    try {
      const {
        status,
        data: { htmlFile: html },
      } = await getAdditionalDocument(id);
      if (status === 200)
        PassDataToEditor({ type: TYPE_CONVERTED_HTML, html, status: STATUS_SUCCESS });
    } catch (e) {
      PassDataToEditor({
        type: TYPE_CONVERTED_HTML,
        status: STATUS_FAIL,
        message: e?.response?.data?.errorTranslation,
      });
    }
  };

  const handleMessage = useCallback((event) => {
    switch (event.data.type) {
      case "save":
        save(event);
        break;
      case "convertToHtml":
        convertToHtml(event);
        break;
      case "getConvertedHtml":
        getConvertedHtml(event);
        break;
      case "close":
        close();
        break;
      default:
    }
  }, []);

  const startEditor = async () => {
    try {
      setLoader(() => ({ isLoading: true, label: "loadingdocument" }));
      const {
        status,
        data: { html, convertedFiles, reports, noteTags, footnotes },
      } = await startEditorApiCall({ projectShortGuid }, { cancelToken: SOURCE.token });
      if (status === 200) {
        const editorContainer = document.getElementById("editor-container");
        if (editorContainer.childNodes && editorContainer.childNodes.length === 0) {
          InitializeEditor(editorContainer, html, convertedFiles, reports, noteTags, footnotes);
          window.addEventListener("message", handleMessage);
        }
      }
    } catch (e) {
      if (!axios.isCancel(e))
        showErrorSnackbar({ content: e?.response?.data?.errorTranslation || "unhandlederror" });
      close();
    } finally {
      setLoader(() => ({ isLoading: false, label: "" }));
    }
  };

  const handleAbort = () => {
    SOURCE.cancel();
    SOURCE = CANCEL_TOKEN.source();
  };

  useEffect(() => {
    if (isOpen) startEditor();
    else window.removeEventListener("message", handleMessage);
  }, [isOpen]);

  return (
    <Modal open={isOpen}>
      <Fade in={isOpen} timeout={50}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Card sx={{ height: "100vh", width: "100vw" }}>
              {loader.isLoading ? (
                <EditDocumentLoader label={loader.label} cancel={handleAbort} />
              ) : null}
              <div id="editor-container" style={{ height: loader.isLoading ? "0vh" : "100vh" }} />
            </Card>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
}

function EditDocumentLoader({ label, cancel }) {
  const { t } = useTranslation();

  const dotAnimation = keyframes`
    0%, 80%, 100% { opacity: 0; }
    40% { opacity: 1; }
  `;

  const Dot = styled("span")(() => ({
    animation: `${dotAnimation} 1.2s infinite`,
  }));

  const DOTS = ["DOT-1", "DOT-2", "DOT-3"];

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      justifyContent="center"
      style={{ height: "100%" }}
    >
      <Grid item>
        <MDTypography variant="h3">
          {t(`projects.details.reports.${label}`)}
          {DOTS.map((i, idx) => (
            <Dot key={i} style={{ animationDelay: `${0.2 * idx}s` }}>
              .
            </Dot>
          ))}
        </MDTypography>
      </Grid>
      <Grid item pt={5}>
        <MDButton variant="gradient" color="dark" onClick={cancel}>
          {t("cancel")}
        </MDButton>
      </Grid>
    </Grid>
  );
}

export default EditDocumentModal;
